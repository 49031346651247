import React, { useState } from "react";
import HospitalBooking from "../hospital-booking/HospitalBooking";
import Booking from "../booking/Booking";
import DoctorDetail from "../doctor-detail/DoctorDetail";
import AppointmentDetails from "../appointment-details/AppointmentDetails";
import BookingCalendar from "../booking-calendar/BookingCalendar";
import PaymentForm from "../payment-form/PaymentForm";
import HospitalPagesNavigate from "../hospital-pages-navigate/HospitalPagesNavigate";
const HandleBookingPage = () => {
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [viewAppointmentDetails, setViewAppointmentDetails] = useState(false);
  const [confirmedAppointment, setConfirmedAppointment] = useState(null);
  const [viewCalendar, setViewCalendar] = useState(false);
  const [viewPaymentForm, setViewPaymentForm] = useState(false);

  const handleBackToDoctors = () => setSelectedDoctor(null);

  const handleBookAppointment = () => setViewAppointmentDetails(true);

  const handleBackToDoctorDetails = () => setViewAppointmentDetails(false);

  const handleConfirmAppointment = (appointment) => {
    setConfirmedAppointment(appointment);
    setViewCalendar(true);
  };

  const handleBackToAppointmentDetails = () => setViewCalendar(false);

  const handleCalendarConfirm = () => {
    setViewCalendar(false); // Hide the calendar
    setViewPaymentForm(true); // Show the PaymentForm page
  };

  const handleBackToCalendar = () => setViewPaymentForm(false);

  console.log("-------->>>>>>>>>", selectedDoctor);

  return (
    <>
      <HospitalPagesNavigate />
    </>
  );
};

export default HandleBookingPage;
