export const splitCamelCase = (str) => {
  return str
    .replace(/([A-Z])/g, " $1") // Add a space before each capital letter
    .replace(/^./, (match) => match.toUpperCase()); // Capitalize the first letter
};

export const handleFilterNumber = (e) => {
  if (!/^\d$/.test(e.key)) {
    e.preventDefault();
  }
};
