import React, { useState, useEffect } from "react";
import { Button, Form, message, Modal, Select, Spin } from "antd";
import useFetch from "../../../../hooks/useFetch";
import ShiftDayAndTimeForm from "./ShiftDayAndTimeForm";
import FormCustomList from "../../../form-list-comp/FormCustomList";

const HospitalAddDoctorsForm = ({
  form,
  shiftForm,
  handleModalOk,
  shiftList,
  setShiftList,
}) => {
  const [searchData, setSearchData] = useState([]);
  const [searching, setSearching] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [shiftsMap, setShiftsMap] = useState({}); // State to store shifts for each doctor

  const [fetchShiftLoading, setFetchShiftLoading] = useState(false);
  const [fetchShiftError, setFetchShiftError] = useState(null);

  const { data, getRequest } = useFetch("/api/users/search");
  const { getRequest: getShift } = useFetch("/api/doctor");

  useEffect(() => {
    if (data?.data) {
      setSearchData(data?.data);
    }
  }, [data]);

  const handleSearch = async (query) => {
    setSearching(true);
    await getRequest({ role: "doctor", query });
    setSearching(false);
  };

  const handleChange = async (val) => {
    const selectedDoctor = searchData.find((user) => user.userId === val);
    if (selectedDoctor) {
      form.setFieldsValue({
        userId: selectedDoctor.userId,
        fullName: selectedDoctor.fullName,
      });
      setFetchShiftLoading(true);
      try {
        const shifts = await getShift({ userId: selectedDoctor.userId });
        setShiftList(shifts?.data || []); // Update the shiftList with fetched shifts
      } catch (error) {
        console.error("Failed to fetch shifts:", error);
        setFetchShiftError(error);
      } finally {
        setFetchShiftLoading(false);
      }
    }
  };

  const handleModalCancel = () => setShowModal(false);

  const handleSelectDate = () => {
    setShowModal(true);
  };

  const handleRemoveShift = (shift) => {
    setShiftList(shiftList.filter((item) => item.id !== shift.id));
  };

  return (
    <>
      <Form
        onFinish={() => handleModalOk(shiftList)}
        form={form}
        layout="vertical"
      >
        <Form.Item
          label="Doctor"
          name="userId"
          rules={[{ required: true, message: "Please select a doctor!" }]}
        >
          <Select
            showSearch
            onChange={handleChange}
            placeholder="Search and select"
            tokenSeparators={[","]}
            notFoundContent={searching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={handleSearch}
            style={{ width: "100%" }}
          >
            {searchData.map((item) => (
              <Select.Option key={item.userId} value={item.userId}>
                {item.fullName} {item.phone}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Button
          type="primary"
          onClick={handleSelectDate}
          style={{ marginBottom: 16 }}
        >
          Add Shift Day and Time
        </Button>
        <Form.Item
          label={shiftList?.length > 0 && "Shifts"}
          name="shifts"
          help={
            shiftList?.length === 0
              ? "Please add at least one shift."
              : fetchShiftError
              ? "Failed to load shifts."
              : null
          }
        >
          <FormCustomList
            dataSource={shiftList}
            handleRemoveItem={handleRemoveShift}
          />
        </Form.Item>
        <Modal
          title="Select Shift Date and Time"
          open={showModal}
          onCancel={handleModalCancel}
          footer={null}
        >
          <ShiftDayAndTimeForm
            setShiftList={setShiftList}
            shiftList={shiftList}
            onCancel={handleModalCancel}
            shiftForm={shiftForm}
          />
        </Modal>
      </Form>
    </>
  );
};

export default HospitalAddDoctorsForm;
