import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Row, Col, Input } from "antd";
import usePost from "../../../hooks/usePost";
import useToast from "../toast/toast";
import useFetch from "../../../hooks/useFetch";
import { useAdminAccAuth } from "../../../contexts/AdminAccountAuth";
import "./Login.css";

const AdminLoginForm = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const { authAdmin, setAuthAdmin } = useAdminAccAuth();
  const { post, reset } = usePost("/api/admin-login", {
    setData,
    setLoading,
    setError,
    setRedirect,
  });

  const {
    data: codeRes,
    error: codeError,
    getRequest: getCode,
  } = useFetch("/api/admin-code");

  const handleSubmit = async (values) => {
    await form.validateFields();
    await post(values);
  };

  useEffect(() => {
    console.log("AuthAdmin state changed:", authAdmin);
  }, [authAdmin]);

  useEffect(() => {
    if (data) {
      showToast(data.status);
      if (data.status === "success" && data.adminAccessToken) {
        localStorage.setItem("adminAccessToken", data.adminAccessToken);
        setAuthAdmin({ authenticated: true });
        navigate("/admin");
      }
      reset();
    }
  }, [data]);

  useEffect(() => {
    if (codeRes?.status === "success") {
      showToast("Code sent");
    } else if (codeRes?.status === "error") {
      showToast("Code not sent");
    }
  }, [codeRes]);

  useEffect(() => {
    if (codeError) {
      showToast(codeError.message?.replace("AxiosError:", ""));
    }
  }, [codeError]);

  useEffect(() => {
    if (error) {
      showToast(error.message?.replace("AxiosError:", ""));
    }
    reset();
  }, [error]);

  const handleSendCode = async () => {
    getCode();
  };

  console.log("loading", loading);

  return (
    <div id="AdminLogin" className="form-container">
      <Form
        className="login-form"
        initialValues={{ remember: true }}
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item label="Admin Login" />

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please fill your Password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Row justify="space-between" align="middle">
          <Col span={18}>
            <Form.Item
              label="Code"
              name="code"
              rules={[
                {
                  required: true,
                  message: "Please fill your Code sent to your telegram!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Button onClick={handleSendCode}>Get</Button>
          </Col>
        </Row>

        <Link to="/forgot_password">Forgot Password?</Link>

        <Form.Item style={{ marginTop: 10 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminLoginForm;
