import { Button, List } from "antd";
import React from "react";
import "./FormCustomList.css";
import { splitCamelCase } from "../../utils/helper";

const FormCustomList = ({ dataSource = [], handleRemoveItem = () => {} }) => {
  return (
    <>
      {dataSource.length > 0 ? (
        <List
          className="ListContainer"
          bordered
          itemLayout="horizontal"
          dataSource={dataSource}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  onClick={() => handleRemoveItem(item)}
                  className="delete-btn"
                >
                  Remove
                </Button>,
              ]}
            >
              <TxtComp item={item} />
            </List.Item>
          )}
        />
      ) : null}
    </>
  );
};

const TxtComp = ({ item }) => {
  if (!item) return null;

  console.log("item", item);

  if (item?.shift?.map) {
    item.shift = `${item?.shift
      .map((time) => time.format("HH:mm"))
      .join(" to ")}`;
  }

  return (
    <div className="formListItems">
      {Object.entries(item).map(([key, value]) => {
        if (typeof value === "object" && value !== null) {
          value = JSON.stringify(value); // Convert to string
        }

        // Split camelCase key into separate words
        const formattedKey = splitCamelCase(key);

        return (
          <span key={key} className="key-value-pair">
            <strong>{formattedKey}:</strong>
            <span className="value">{value} </span>
          </span>
        );
      })}
    </div>
  );
};

export default FormCustomList;
