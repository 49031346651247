import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Space,
  Avatar,
  Badge,
  Divider,
  List,
  message,
  Rate,
  Button,
} from "antd";
import VirtualList from "rc-virtual-list";
import useFetch from "../../../hooks/useFetch";
const { Title, Text } = Typography;
const ContainerHeight = 200;

const DoctorDetail = ({
  doctor,
  onBack,
  onBook,
  hospital,
  onHospitalClick,
}) => {
  const [hospList, setHospList] = useState([]);

  const { data: hospitalsData, getRequest } = useFetch("/api/doctor/hospital");

  const {
    userId,
    fullName,
    profImg,
    online,
    specialty,
    licenseNumber,
    rating,
    experience,
    sex,
  } = doctor;

  useEffect(() => {
    if (userId) {
      getRequest({ drId: userId });
    }
  }, [userId]);

  useEffect(() => {
    if (hospitalsData?.data) {
      setHospList(hospitalsData.data);
      console.log(hospitalsData.data, "--------------hospitalData------------");
    }
  }, [hospitalsData]);

  const handleClick = (item) => {
    // message.info(`You clicked on ${item.organizationName}`);
    if (onHospitalClick) {
      onHospitalClick(item); // Navigate to HospitalDetails with the selected hospital
    }
  };

  return (
    <div style={{ padding: "20px", display: "flex", justifyContent: "center" }}>
      <Card
        style={{
          maxWidth: 600,
          width: "100%",
          borderRadius: "10px",
          boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
        }}
        id="doctor-detail"
      >
        <Space direction="vertical" style={{ width: "100%" }} size="large">
          <div style={{ textAlign: "center" }}>
            <Avatar src={`/images/user/${profImg}`} size={100} />
            <Title level={3} style={{ marginTop: 10 }}>
              Dr {fullName}
            </Title>
            <Badge
              status={online ? "success" : "default"}
              text={online ? "Online" : "Offline"}
            />
          </div>

          <Divider />

          <Space direction="vertical" size="small" style={{ width: "100%" }}>
            <Text>Gender : {sex}</Text>
          </Space>
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Text strong>Specialty: {specialty}</Text>
            <Text>License Number: {licenseNumber}</Text>
            <Text>{experience} years experience</Text>
          </Space>

          <Divider />

          {/* Service Places List with Header */}
          <List
            header={<Title level={4}>Service Places</Title>}
            style={{ width: "100%" }}
          >
            <VirtualList
              data={hospList}
              height={ContainerHeight}
              itemHeight={47}
              itemKey="branchNumber"
            >
              {(item) => (
                <List.Item
                  key={item.branchNumber}
                  onClick={() => handleClick(item)}
                  style={{ cursor: "pointer" }}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar src={`/images/hospitals/${item.profImg}`} />
                    }
                    title={<h6>{item.organizationName}</h6>}
                    description={`Phone: ${item.phone}`}
                  />
                </List.Item>
              )}
            </VirtualList>
          </List>

          <Divider />

          <Rate disabled defaultValue={rating} />
          <Button type="primary" block onClick={onBook}>
            Book Appointment
          </Button>
          <Button type="primary " block>
            get consulting
          </Button>
          <Button type="link" onClick={onBack} block>
            Back
          </Button>
        </Space>
      </Card>
    </div>
  );
};

export default DoctorDetail;
