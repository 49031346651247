import React, { useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Badge,
  Rate,
  Avatar,
  Space,
  Typography,
  Divider,
} from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

const cardData = [];

const Booking = ({ onCardClick }) => {
  return (
    <div style={{ padding: "20px" }}>
      <Row gutter={[16, 16]}>
        {cardData.map((card, index) => (
          <Col xs={24} sm={12} md={8} lg={6} key={index}>
            <Card
              bordered={false}
              style={{
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
              }}
              onClick={() => onCardClick(card)}
            >
              <Avatar src={card.image} size={80} style={{ marginBottom: 10 }} />
              <Title level={5}>{card.name}</Title>
              <Badge
                status={card.online ? "success" : "default"}
                text={card.online ? "Online" : "Offline"}
              />
              <Text type="secondary">
                <EnvironmentOutlined /> {card.location}
              </Text>
              <Text>{card.specialty}</Text>
              <Divider />
              <div style={{ textAlign: "center" }}>
                <Rate allowHalf defaultValue={card.rating} disabled />
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Booking;
