import React, { useState } from "react";
import { Card, Calendar, Radio, Space, Button, Typography } from "antd";
import {
  ClockCircleOutlined,
  CoffeeOutlined,
  MoonOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Title, Text } = Typography;

const BookingCalendar = ({ doctor, onConfirm, onBack }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(null);
  const [shiftTimes, setShiftTimes] = useState(null); // Store start and end time of the selected shift

  const today = moment();

  // Handle date selection
  const handleDateSelect = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
    setSelectedTimePeriod(null); // Reset time period when selecting a new date
    setShiftTimes(null); // Reset time shift when selecting a new date
  };

  // Handle time period change (morning, afternoon, evening)
  const handleTimePeriodChange = (e) => {
    const timePeriod = e.target.value;
    setSelectedTimePeriod(timePeriod);

    // Fetch start and end times for the selected time shift
    const dayOfWeek = moment(selectedDate).format("dddd");
    const availabilityForDay = doctor.dates?.availability[dayOfWeek];

    if (availabilityForDay) {
      const timeShift = availabilityForDay.timeShift[timePeriod];
      if (timeShift) {
        setShiftTimes({ start: timeShift.start, end: timeShift.end });
      } else {
        setShiftTimes(null);
      }
    }
  };

  // Disable unavailable days (before today, rest days)
  const disabledDate = (current) => {
    console.log("current date", current);
    if (!current) return false;

    const formattedDay = current?.format("dddd");
    console.log("formatted day", formattedDay);
    console.log("doctor availability", doctor);
    const isRestDay = doctor?.availability[formattedDay]?.period === "rest";
    console.log("isRest day", isRestDay);
    return current < today?.startOf("day") || isRestDay;
  };

  // Confirm booking handler
  const handleConfirmBooking = () => {
    if (selectedDate && selectedTimePeriod && shiftTimes) {
      onConfirm(); // Proceed to the next step (could be submitting or navigating)
    } else {
      alert("Please select a date and a time period.");
    }
  };

  return (
    <div style={{ padding: "20px", display: "flex", justifyContent: "center" }}>
      <Card
        style={{
          maxWidth: 600,
          borderRadius: "10px",
          boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Title level={4}>
            Dr. {doctor.name} - {doctor.specialty}
          </Title>

          <Calendar
            fullscreen={false}
            onSelect={handleDateSelect}
            disabledDate={disabledDate}
          />

          {selectedDate && (
            <>
              <Title level={5}>Select Time Period</Title>
              <Radio.Group
                onChange={handleTimePeriodChange}
                value={selectedTimePeriod}
              >
                <Space direction="vertical">
                  <Radio value="morning">
                    <ClockCircleOutlined /> Morning
                  </Radio>
                  <Radio value="afternoon">
                    <CoffeeOutlined /> Afternoon
                  </Radio>
                  <Radio value="evening">
                    <MoonOutlined /> Evening
                  </Radio>
                </Space>
              </Radio.Group>

              {shiftTimes && (
                <div>
                  <Title level={5}>Selected Time Shift</Title>
                  <Text>
                    Start: {shiftTimes.start} - End: {shiftTimes.end}
                  </Text>
                </div>
              )}

              <Button
                type="primary"
                block
                onClick={handleConfirmBooking}
                disabled={!selectedDate || !selectedTimePeriod || !shiftTimes}
              >
                Confirm Booking
              </Button>
            </>
          )}

          <Button type="link" onClick={onBack} block>
            Back to Appointment Details
          </Button>
        </Space>
      </Card>
    </div>
  );
};

export default BookingCalendar;
