import React, { useState } from "react";
import { Select, DatePicker, Space, Button, message, Form } from "antd";

const { RangePicker } = DatePicker;

const daysOfWeek = [
  { label: "Sunday", value: "Sunday" },
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
];

const ShiftDayAndTimeForm = ({ setShiftList, shiftList, onCancel }) => {
  const [shiftForm] = Form.useForm();

  const handleAddShift = async () => {
    try {
      const value = await shiftForm.validateFields();

      setShiftList([...shiftList, value]);
      onCancel();
      shiftForm.resetFields();
    } catch (error) {
      if (error?.errorFields) {
        error?.errorFields.forEach((element, index) => {
          if (index < 4) message.error(element.errors[0]);
        });
      }
    }
  };

  return (
    <Form form={shiftForm} layout="vertical" onFinish={handleAddShift}>
      <Space direction="vertical" size={12}>
        <Form.Item
          label="Select Day"
          name="day"
          rules={[
            { required: true, message: "Please select at least one day!" },
          ]}
        >
          <Select
            placeholder="Select day"
            options={daysOfWeek}
            style={{ width: "100%", marginTop: "8px" }}
          />
        </Form.Item>
        <Form.Item
          label="Select Shift"
          name="shift"
          rules={[{ required: true, message: "Please select shift!" }]}
        >
          <RangePicker picker="time" format="HH:mm" />
        </Form.Item>

        <Space>
          <Button type="primary" onClick={handleAddShift}>
            Add Shift
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Space>
      </Space>
    </Form>
  );
};

export default ShiftDayAndTimeForm;
