import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import SymptomSelector from "../symptom-selector/SymptomSelector";
import MaleBody from "../../body-part/MaleBody";
import FemaleBody from "../../body-part/FemaleBody";
import ChildBody from "../../body-part/ChildBody";
import PelvisSelectorModal from "./PelvisSelectorModal";
import BreastSelectorModal from "./BreastSelectorModal";
import HeadSelectorModal from "./HeadSelectorModal";

const VisualBodySelector = ({ onNext, formData, setFormData }) => {
  const [isFrontVisible, setIsFrontVisible] = useState(true);
  const [isSymptomModalOpen, setIsSymptomModalOpen] = useState(false);
  const [selectedBodyPartImage, setSelectedBodyPartImage] = useState(<></>);
  const [targetSymptom, setTargetSymptom] = useState("");
  const [openPelvisSelectorModal, setOpenPelvisSelectorModal] = useState(false);
  const [openBreastSelectorModal, setOpenBreastSelectorModal] = useState(false);
  const [openHeadSelectorModal, setOpenHeadSelectorModal] = useState(false);

  const toggleCardVisibility = () => {
    setIsFrontVisible((prev) => !prev);
  };

  const selectBodyPart = (part) => {
    setFormData({
      ...formData,
      selectedBodyPart: part,
    });
    console.log(targetSymptom);
  };

  const handleAdultPathClick = (e) => {
    const clickedBodyPart = e.currentTarget.getAttribute("data-inkscape-label");
    if (clickedBodyPart === "Head and Face") {
      console.log("Head and Face clicked");
      console.log("the form data", formData.isPregnant);
      if (formData.isPregnant) {
        selectBodyPart("Head");
        return setIsSymptomModalOpen(true);
      }

      return setOpenHeadSelectorModal(true);
    }
    if (clickedBodyPart === "Pelvis" && formData.isPregnant === false)
      return setOpenPelvisSelectorModal(true);
    if (clickedBodyPart === "Chest" && formData.gender === "female")
      return setOpenBreastSelectorModal(true);

    selectBodyPart(clickedBodyPart);
    setIsSymptomModalOpen(true);
  };
  const handlePediatricPathClick = (e) => {
    const clickedBodyPart = e.currentTarget.getAttribute("data-inkscape-label");
    if (clickedBodyPart === "Head and Face") {
      return setOpenHeadSelectorModal(true);
      selectBodyPart(clickedBodyPart);
    }
    if (clickedBodyPart === "Pelvis") {
      let updateBodyPart = clickedBodyPart;
      updateBodyPart = "Genitalia";
      selectBodyPart(updateBodyPart);
    }
    setIsSymptomModalOpen(true);
  };

  const handleSelectPelvisPart = (value) => {
    selectBodyPart(value);
    setOpenPelvisSelectorModal(false);
    setIsSymptomModalOpen(true);
  };
  const handleSelectBreastPart = (value) => {
    selectBodyPart(value);
    setOpenBreastSelectorModal(false);
    setIsSymptomModalOpen(true);
  };
  const handleSelectHeadPart = (value) => {
    selectBodyPart(value);
    setOpenHeadSelectorModal(false);
    setIsSymptomModalOpen(true);
  };
  const additionalPrediction =
    " NOTE: These questions are related only to pregnancy other medical conditions still can happen during pregnancy so additional to these symptoms other symptoms which are included in adult female should also be filled in adult section.";

  useEffect(() => {
    if (formData.age <= 5) {
      switch (formData.gender) {
        case "male":
          setSelectedBodyPartImage(
            <MaleBody
              age={formData.age}
              handlePathClick={handlePediatricPathClick}
              toggleCardVisibility={toggleCardVisibility}
              isFrontVisible={isFrontVisible}
            />
          );
          setTargetSymptom("Male Baby Symptoms");
          break;
        case "female":
          setSelectedBodyPartImage(
            <FemaleBody
              age={formData.age}
              handlePathClick={handlePediatricPathClick}
              toggleCardVisibility={toggleCardVisibility}
              isFrontVisible={isFrontVisible}
            />
          );
          setTargetSymptom("Female Baby Symptoms");
          break;
        default:
          setSelectedBodyPartImage(null);
      }
    } else if (formData.age > 5 && formData.age <= 13) {
      switch (formData.gender) {
        case "male":
          setSelectedBodyPartImage(
            <MaleBody
              age={formData.age}
              handlePathClick={handlePediatricPathClick}
              toggleCardVisibility={toggleCardVisibility}
              isFrontVisible={isFrontVisible}
            />
          );
          setTargetSymptom("Male Pediatrics Symptoms");
          break;
        case "female":
          setSelectedBodyPartImage(
            <FemaleBody
              age={formData.age}
              handlePathClick={handleAdultPathClick}
              toggleCardVisibility={toggleCardVisibility}
              isFrontVisible={isFrontVisible}
            />
          );
          setTargetSymptom("Female Pediatrics Symptoms");
          break;
        default:
          setSelectedBodyPartImage(null);
      }
    } else if (formData.age > 13) {
      switch (formData.gender) {
        case "male":
          setSelectedBodyPartImage(
            <MaleBody
              age={formData.age}
              handlePathClick={handleAdultPathClick}
              toggleCardVisibility={toggleCardVisibility}
              isFrontVisible={isFrontVisible}
            />
          );
          setTargetSymptom("Male Adult Symptoms");
          break;
        case "female":
          setSelectedBodyPartImage(
            <FemaleBody
              age={formData.age}
              isPregnant={formData.isPregnant}
              additionalPrediction={additionalPrediction}
              handlePathClick={handleAdultPathClick}
              toggleCardVisibility={toggleCardVisibility}
              isFrontVisible={isFrontVisible}
            />
          );
          setTargetSymptom(
            formData.isPregnant ? "Pregnant Symptoms" : "Female Adult Symptoms"
          );
          break;
        default:
          setSelectedBodyPartImage(null);
      }
    }
  }, [formData.age, formData.gender, formData.isPregnant]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
      }}
    >
      {selectedBodyPartImage}
      <Modal
        open={isSymptomModalOpen}
        footer={null}
        onCancel={() => setIsSymptomModalOpen(false)}
      >
        <SymptomSelector
          targetSymptom={targetSymptom}
          gender={formData.gender}
          onNext={onNext}
          formData={formData}
          setFormData={setFormData}
        />
      </Modal>
      <PelvisSelectorModal
        openPelvisSelectorModal={openPelvisSelectorModal}
        setOpenPelvisSelectorModal={setOpenPelvisSelectorModal}
        handleSelectPelvisPart={handleSelectPelvisPart}
      />
      <BreastSelectorModal
        openBreastSelectorModal={openBreastSelectorModal}
        setOpenBreastSelectorModal={setOpenBreastSelectorModal}
        handleSelectBreastPart={handleSelectBreastPart}
      />
      <HeadSelectorModal
        openHeadSelectorModal={openHeadSelectorModal}
        setOpenHeadSelectorModal={setOpenHeadSelectorModal}
        handleSelectHeadPart={handleSelectHeadPart}
      />
    </div>
  );
};

export default VisualBodySelector;
